import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import AnimationContainer from 'components/animation-container';
import ContactCreativeForm from './parts/ContactCreativeForm.js';

class ContactCreative extends React.Component {
  render() {
    const Section = styled.section`
      position: relative;
      overflow: hidden;
      background-color: rgb(22, 26, 40,0.95);
      padding: 100px 0;
    `;

    const FormRow = styled(Row)`
      background-color: #111;
    `;

    const ContactCol = styled(Col)`
      min-height: 600px;
      max-height: 600px;
      padding: 0;
      display: flex;
      align-items: center;
    `;

    const Map = styled.iframe`
      border: none;
      height: 100%;
      width: 100%;
    `;

    const IconRow = styled(Row)`
      margin-top: 150px;
    `;

    const IconCol = styled(Col)`
      @media (max-width: 500px) {
        margin-bottom: 140px;
      }
    `;

    const IconContainer = styled.div`
      width: 150px;
      height: 150px;
      margin: auto;
      padding: 35px;
      text-align: center;
      position: relative;
      bottom: 75px;
      background-color: #ff0084;
      border-radius: 150px;
      transition: 0.5s;
    `;

    const InfoPart = styled.div`
      min-height: 250px;
      background-color: #111;
      border: 2px solid #444;
      &:hover ${IconContainer} {
        background-color: #009e9e;
      }
    `;
    const Icon = styled.img`
      height: 70px;
      width: 70px;
      object-fit: contain;
    `;

    const InfoTitle = styled.h4`
      font-size: 35px;
      color: #fff;
      font-family: Teko;
      text-align: center;
    `;

    const Info = styled.div`
      position: relative;
      bottom: 30px;
    `;

    const InfoLinkContainer = styled.div`
      display: flex;
      flex-direction: column;
      text-align: center;
    `;

    const InfoLink = styled.a`
      color: #ff0084;
      transition: 0.5s;
      &:hover {
        color: #fff;
        text-decoration: none;
      }
    `;

    return (
      <Section id="contact">
        <Container>
          <AnimationContainer animation="fadeIn">
            <FormRow>
              <ContactCol md={12}>
                <ContactCreativeForm />
              </ContactCol>
              {/* <ContactCol md={6}>
                <Map
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.144078247158!2d74.28944171490518!3d31.465222581387884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919015f20c9fff7%3A0x6a7252b4907cf96f!2sPopCorn%20Studio%20Johar%20Town!5e0!3m2!1sen!2sde!4v1643122099725!5m2!1sen!2sde"
                />
              </ContactCol> */}
            </FormRow>
          </AnimationContainer>
          <IconRow>
            <IconCol md={4}>
              <AnimationContainer animation="fadeIn" delay={500}>
                <InfoPart>
                  <IconContainer>
                    <Icon
                      src={this.props.emailIcon.childImageSharp.fluid.src}
                      alt="email"
                    />
                  </IconContainer>
                  <Info>
                    <InfoTitle>Email</InfoTitle>
                    <InfoLinkContainer>
                      <InfoLink href="mailto:info@ideegames.com">
                        info@ideegames.com
                      </InfoLink>
                    </InfoLinkContainer>
                  </Info>
                </InfoPart>
              </AnimationContainer>
            </IconCol>
            <IconCol md={4}>
              <AnimationContainer animation="fadeIn" delay={1000}>
                <InfoPart>
                  <IconContainer>
                    <Icon
                      src={this.props.phoneIcon.childImageSharp.fluid.src}
                      alt="phone"
                    />
                  </IconContainer>
                  <Info>
                    <InfoTitle>Phone</InfoTitle>
                    <InfoLinkContainer>
                      <InfoLink href="tel:+923090338705">
                        +31 610972269
                      </InfoLink>

                      <InfoLink href="tel:+4917634649431">
                        +4917634649431
                      </InfoLink>
                    </InfoLinkContainer>
                  </Info>
                </InfoPart>
              </AnimationContainer>
            </IconCol>
            <IconCol md={4}>
              <AnimationContainer animation="fadeIn" delay={1500}>
                <InfoPart>
                  <IconContainer>
                    <Icon
                      src={this.props.mapIcon.childImageSharp.fluid.src}
                      alt="map"
                    />
                  </IconContainer>
                  <Info>
                    <InfoTitle>Head Office Address</InfoTitle>
                    <InfoLinkContainer>
                      <InfoLink
                        target="_blank"
                        href="https://goo.gl/maps/LhSFnaH3aVtrLJiw6"
                      >
                        Versailleslaan 12 5627LW Eindhoven The Netherlands
                      </InfoLink>
                    </InfoLinkContainer>
                  </Info>
                </InfoPart>
              </AnimationContainer>
            </IconCol>
          </IconRow>
        </Container>
      </Section>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        emailIcon: file(relativePath: { eq: "icons/email2.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        mapIcon: file(relativePath: { eq: "icons/map.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        phoneIcon: file(relativePath: { eq: "icons/phone.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }
    `}
    render={({ emailIcon, mapIcon, phoneIcon }) => (
      <ContactCreative
        emailIcon={emailIcon}
        mapIcon={mapIcon}
        phoneIcon={phoneIcon}
        {...props}
      />
    )}
  />
);
