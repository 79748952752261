import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';
import LoopVideo from './assets/game-over.mp4';

class HeroVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      width: 0,
    };
  }
  updateDimensions = () => {
    if (this.state.height !== window.innerHeight) {
      this.setState({ height: window.innerHeight });
    }
    if (this.state.width !== window.innerWidth) {
      this.setState({ width: window.innerWidth });
    }
  };

  componentDidMount() {
    this.setState({ height: window.innerHeight, width: window.innerWidth });
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const Section = styled.section`
      position: relative;
      background:rgb(22, 26, 40);
      .particles {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      background-size: cover;
      background-repeat: no-repeat;
    `;

    const VideoContainer = styled.div`
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      position: relative;
      video {
        position: absolute;
      }
    `;
    const Heading1 = styled.h1`
      font-size: 80px;
      font-family: Teko;
      line-height: 40px;
      color: #fff;
      font-weight: 400;
      text-transform: uppercase;
      @media (min-width: 768px) {
        margin-left: 4px;
      }
      @media (max-width: 767px) {
        margin-left: 2px;
      }
      @media (min-width: 768px) and (max-width: 1500px) {
        font-size: 40px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 20px;
      }
    `;

    const Heading2 = styled.h2`
      font-size: 120px;
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      background: #11998e;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #38ef7d, #11998e);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media (max-width: 767px) {
        font-size: 40px;
        line-height: 40px;
      }
      @media (min-width: 768px) and (max-width: 1500px) {
        font-size: 70px;
      }
    `;

    const Type = styled.div`
      font-size: 50px;
      line-height: 50px;
      color: #fff;
      text-transform: uppercase;
      @media (min-width: 768px) {
        margin-left: 6px;
      }
      @media (max-width: 767px) {
        margin-left: 2px;
      }
      @media (min-width: 768px) and (max-width: 1500px) {
        font-size: 23px;
        line-height: 20px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 20px;
      }
      span {
        font-family: Teko;
      }
    `;
    const Content = styled.div`
      position: relative;
      z-index: 10;
    `;

    const Separator = styled.div`
      height: 5px;
      width: 50px;
      background-color: #ff0084;
      margin-bottom: 30px;
      @media (min-width: 768px) {
        margin-left: 6px;
      }
      @media (max-width: 767px) {
        margin-left: 2px;
      }
    `;

    const BottomContent = styled.div`
      width: 100%;
      background:rgb(22, 26, 40,0.5);
      margin-top: 50px;
      z-index: 2;
    `;

    const ServiceCol = styled(Col)`
      padding: 0;
      margin-top: 20px;
      border-right: #444;
      &.no-border {
        border-right: none;
      }
      @media (max-width: 500px) {
        border-right: none;
      }
    `;

    const Service = styled.div`
      min-height: 400px;
      background-color: rgba(15, 18, 28);
      &:hover {
        background-color: rgb(22, 26, 40,1);
      }
    `;
    const ServiceContent = styled.div`
      padding: 40px;
      color: #fff;
      min-height: 50px;
      height: 50px;
      p {
        font-size: 14px;
        font-weight: 300;
        color: #efefef;
      }
    `;
    const ServiceHeading = styled.h4`
      font-size: 30px;
      font-weight: 400;
      font-family: Teko;
    `;
    const ServiceSeparator = styled.div`
      height: 5px;
      width: 50px;
      background-color: #ff0084;
      margin-bottom: 10px;
    `;

    const ServiceIcon = styled.div`
      margin-bottom: 20px;
      img {
        max-height: 50px;
      }
    `;

    const ServiceText = styled.p`
      font-size: 14px;
      font-weight: 300;
      color: #c5c5c5;
    `;

    return (
      <Section id="home">
        <VideoContainer
          style={{
            height: `${this.state.width > 500 ? this.state.height : 350}px`,
          }}
        >
          <video
            autoPlay="autoplay"
            loop="loop"
            muted
            style={{
              height: `${
                this.state.width >= 1024 && this.state.width < 1200
                  ? '100%'
                  : 'auto'
              }`,
            }}
          >
            <source src={LoopVideo} type="video/mp4" />
          </video>
          <Container>
            <Content>
              <Heading1>Visionize</Heading1>
              <Heading2>Web & Mobile Solutions</Heading2>
              <ServiceSeparator />
              <Type>
                <Typewriter
                  options={{
                    strings: [
                      'App Developers',
                      'Web Designers',
                      'UX Designers',
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </Type>
            </Content>
          </Container>
        </VideoContainer>
        <BottomContent id="about">
          <Container>
            <Row>
              <ServiceCol
                md={4}
                style={{
                  borderRight:
                    this.state.width > 500 ? '1px solid #444' : 'none',
                }}
              >
                <Service>
                  <ServiceContent>
                    <ServiceIcon>
                      <img
                        src={this.props.webexpertIcon.childImageSharp.fluid.src}
                        alt="web experts"
                      />
                    </ServiceIcon>
                    <ServiceHeading>Web Experts</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>
                      Growing as the bestas best Digital Marketing Agency, our
                      experts offer and create best web solutions based on best
                      graphic designing and website development. Along with
                      this, the team helps in Google page ranking and security.
                    </ServiceText>
                  </ServiceContent>
                </Service>
              </ServiceCol>
              <ServiceCol
                md={4}
                style={{
                  borderRight:
                    this.state.width > 500 ? '1px solid #444' : 'none',
                }}
              >
                <Service>
                  <ServiceContent>
                    <ServiceIcon>
                      <img
                        src={this.props.mobileIcon.childImageSharp.fluid.src}
                        alt="mobile experts"
                      />
                    </ServiceIcon>
                    <ServiceHeading>Mobile Experts</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>
                      We believe in best Game Art design and development with a
                      harmony that creates the best video games. Our video
                      artists along with the production team reshapes ideas into
                      best visual and concept art.
                    </ServiceText>
                  </ServiceContent>
                </Service>
              </ServiceCol>
              <ServiceCol md={4}>
                <Service>
                  <ServiceContent>
                    <ServiceIcon>
                      <img
                        src={this.props.seoIcon.childImageSharp.fluid.src}
                        alt="seo experts"
                      />
                    </ServiceIcon>
                    <ServiceHeading>Content Experts</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>
                      We believe in the best web marketing channels and through
                      our expertise in content writing, our professionals are
                      keen to explore and implement new strategies. We assure in
                      targeting the right audience with our expertise in web
                      marketing.
                    </ServiceText>
                  </ServiceContent>
                </Service>
              </ServiceCol>
            </Row>
          </Container>
        </BottomContent>
      </Section>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        background: file(relativePath: { eq: "background-poly.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              src
            }
          }
        }
        webexpertIcon: file(relativePath: { eq: "icons/web.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        mobileIcon: file(relativePath: { eq: "icons/mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
        seoIcon: file(relativePath: { eq: "icons/seo.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              src
            }
          }
        }
      }
    `}
    render={({ background, webexpertIcon, mobileIcon, seoIcon }) => (
      <HeroVideo
        background={background}
        webexpertIcon={webexpertIcon}
        mobileIcon={mobileIcon}
        seoIcon={seoIcon}
        {...props}
      />
    )}
  />
);
