    import React from 'react';
    import { Row, Col, Container } from 'react-bootstrap';
    import styled from 'styled-components';
    import AnimationContainer from 'components/animation-container';
    import AnimatedHeading from 'components/animated-heading';

    class ServicesTwo extends React.Component {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        const Section = styled.section`
                position: relative;
                overflow: hidden;
                background-color: rgba(15, 18, 28);
                padding: 100px 0 50px 0;
                .heading {
                    width: 100%;
                }
            }
            `;

        const ServiceRow = styled(Row)`
        margin-top: 100px;
        `;

        const ServiceHeading = styled.h4`
        font-size: 22px;
        font-weight: 500;
        font-family: Teko;
        color: #fff;
        margin-bottom: 0px;
        `;

        const ServiceSeparator = styled.div`
        height: 2px;
        width: 50px;
        background-color: #ff0084;
        margin-bottom: 5px;
        `;

        const ServiceNumber = styled.h5`
        position: absolute;
        font-size: 60px;
        color: #fff;
        font-weight: 900;
        top: -50px;
        opacity: 0.1;
        z-index: -1;
        transition: 0.2s;
        `;

        const ServiceElement = styled.div`
        margin-bottom: 90px;
        border-radius: 20px;
        transition: 0.2s;
        position: relative;
        &:hover ${ServiceNumber} {
            opacity: 0.3 !important;
            color: #ff0084;
        }
        `;

        const ServiceText = styled.p`
        font-size: 11px;
        font-weight: 300;
        color: #c5c5c5;
        padding-right: 25px;
        `;

        return (
        <Section id="services">
            <Container>
            <AnimatedHeading text="Our Services" />
            <ServiceRow>
                <Col md={3}>
                <AnimationContainer animation="fadeInDown" delay={500}>
                    <ServiceElement>
                    <ServiceNumber>01</ServiceNumber>
                    <ServiceHeading>Mobile App Development</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>
                        With Application development expertise, we work in Mobile
                        and web development with technical expertise of UI/UX. With
                        this, user and audience management with our best user
                        experience and testing facility is enhanced.
                    </ServiceText>
                    </ServiceElement>
                </AnimationContainer>
                </Col>
                <Col md={3}>
                <AnimationContainer animation="fadeInDown" delay={1000}>
                    <ServiceElement>
                    <ServiceNumber>02</ServiceNumber>
                    <ServiceHeading>Web Development</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>
                        With intuitive and stunning designs, our experts manage
                        clear web designs with pictorial representations and
                        mockups.
                    </ServiceText>
                    </ServiceElement>
                </AnimationContainer>
                </Col>
                <Col md={3}>
                <AnimationContainer animation="fadeInDown" delay={1500}>
                    <ServiceElement>
                    <ServiceNumber>03</ServiceNumber>
                    <ServiceHeading>Games</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>
                        By staying ahead of the game, we work in mobile gaming in
                        different genres such as simulation, racing, shooting, PvP
                        and hyper casual games. We believe in the best artwork for
                        games to achieve the right target audience with our state of
                        the art work.
                    </ServiceText>
                    </ServiceElement>
                </AnimationContainer>
                </Col>
                <Col md={3}>
                <AnimationContainer animation="fadeInDown" delay={2000}>
                    <ServiceElement>
                    <ServiceNumber>04</ServiceNumber>
                    <ServiceHeading>Digital Marketing</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>
                        With content management, SEO and ASO services, we give
                        complete digital marketing assistance and services for our
                        clients. This includes promotional media like social
                        networking applications, along with different social media
                        marketing packages.
                    </ServiceText>
                    </ServiceElement>
                </AnimationContainer>
                </Col>
                <Col md={3}>
                <AnimationContainer animation="fadeInUp" delay={500}>
                    <ServiceElement>
                    <ServiceNumber>05</ServiceNumber>
                    <ServiceHeading>Amazon web services</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>
                        With AWS storage solutions, we provide our clientele with
                        best data and space management on cloud services. We help in
                        global services which are not only cost effective but also
                        scalable for users in different ways.
                    </ServiceText>
                    </ServiceElement>
                </AnimationContainer>
                </Col>
                <Col md={3}>
                <AnimationContainer animation="fadeInUp" delay={1000}>
                    <ServiceElement>
                    <ServiceNumber>06</ServiceNumber>
                    <ServiceHeading>NFT games</ServiceHeading>
                    <ServiceSeparator />
                    <ServiceText>
                        With a combination of hyper casual, educational, action and
                        adventure games, the concept of using cryptocurrencies and
                        non-fungible tokens, may help in gathering a new subset of
                        target audience.
                    </ServiceText>
                    </ServiceElement>
                </AnimationContainer>
                </Col>
            </ServiceRow>
            </Container>
        </Section>
        );
    }
    }

    export default ServicesTwo;
